<template>
  <div
    v-if="displayIndex"
    id="h4hHotelIndex"
    class="event h4hHotelIndex"
  >
    <div
      id="updateOrderCurrency"
      class="update-order-currency"
      bind="value:name"
      @click="updateOrderCurrency($event)"
    />
    <transition name="fade">
      <div
        v-if="$store.state.hotel.loading"
        class="initial-hotels-load"
      />
    </transition>
    <div
      v-if="!h4hAddOn"
      class="ticket-and-hotel-column"
    >
      <TicketAndHotelIcons class="f1-redesign-hide" />
      <SelectHotelContent
        v-if="!hotelIsSelected"
        class="f1-redesign-show"
      />
    </div>
    <div class="retail-hotel-filter-container mobile-filter">
      <HotelFilter />
    </div>
    <div
      v-if="!$store.state.hotel.showMap && !$store.state.hotel.loading"
      class="hotel-index-container"
    >
      <!-- <div
        v-if="packageJustAdded"
        class="package-added-successfully-parent"
      >
        <div class="package-added-successfully" />
        <span>
          {{ $t('h4h.packageSuccess', {
            package_name: orderItem.package_display_name
          }) }}
        </span>
        <span
          class="pointer"
          @click="packageJustAdded = false"
        >
          <span class="package-success-message" />
        </span>
      </div> -->

      <!-- <H4HBreadcrumbs /> -->

      <div
        v-if="hotelIsSelected"
        class="purchase-path-nav-parent"
      >
        <div
          v-if="hotelIsSelected"
          class="purchase-path-nav"
        />
        <!-- <a
          v-if="!hotelIsSelected"
          class="back-to-package"
          @click="goToPackagePath()"
        >{{ $t('h4h.back') }}</a> -->
        <a
          v-if="hotelIsSelected"
          class="back-to-package pointer"
          role="button"
          @click.prevent="deselectSpecifiedHotel"
        >
          {{ $t("h4h.seeAllProperties") }}
        </a>
      </div>
      <div v-if="!hotelIsSelected">
        <h3
          v-if="h4hAddOn"
          class="hotel-index-header"
        >
          {{ $t("h4h.chooseAccomodation") }}
        </h3>
        <p
          v-if="h4hAddOn"
          class="hotel-index-description"
        >
          {{ $t('h4h.hotelOfferings', {
            brand_name: brandName
          }) }}
        </p>
        <RetailHotelIndex
          :rate="parseFloat(order.exchange_rate)"
          :currency="order.currency"
          :eventid="eventId"
        />
      </div>
      <HotelOverview
        v-if="$store.state.hotel.hotel"
      />
    </div>
  </div>
</template>

<script>
/* global locale Turbo*/

import HotelFilter from './retail_hotel_filter'
import FetchCall from '../../local_fetch_call'
import Hotel from '../../../classes/hotel'
import TicketAndHotelIcons from './icons/hotel-icons'
import SelectHotelContent from './select_hotel_content'
import RetailHotelIndex from './retail_hotel_index'
import HotelOverview from '../h4h_rooms/hotel_overview'
import mixin from './hotel_availability'
import { mapMutations, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
    HotelFilter,
    RetailHotelIndex,
    HotelOverview,
    TicketAndHotelIcons,
    SelectHotelContent
  },

  mixins: [mixin],

  data() {
    return {
      packageJustAdded: false,
      brandName: window.brand_name,
      loading: false,
      contractedHotels: [],
      displayIndex: false,
      eventId:'',
      hotels: [],
      cusRoomcashAmount:0,
      orderItem: {},
      package: {},
      orderHotelId: {
        default: null,
        required: false,
      },
      selectedStay: {
        default: null,
        requried: false,
      },
      roomQty: Number,
    }
  },

  computed: {
    ...mapGetters('hotel', ['getContractedHotels']),
    order(){
      return this.$store.state.order.order
    },
    orderItems(){
      return this.$store.state.order.order.items
    },
    tempPackage() {
      let pack = this.package
      pack.purchasable_hotels = this.hotels
      return pack
    },
    retailHotelsAllowed() {
      return this.order.event_hotel_behavior != 'contracted_only' ? true : false
    },
    h4hAddOn() {
      return this.$store.state.hotel.h4h_add_on
    },

    hotelIsSelected() {
      return this.$store.state.hotel.hotel ? true : false
    },
  },

  watch: {
    order() {
      this.displayIndex = true
      this.updateVenue(this.order.event_venue)
      this.updateH4hPackage(this.order.h4h_add_on)
      this.updateDestination(this.order.event_address)
      this.updateH4hDestination(this.order.event_h4h_address)
      this.updateLatitude(this.order.event_latitude)
      this.updateH4hLatitude(this.order.event_h4h_latitude)
      this.updateLongitude(this.order.event_longitude)
      this.updateH4hLongitude(this.order.event_h4h_longitude)
      this.updateCheckInDate(moment(this.order.hotel_start_date).startOf('day')._d)
      this.updateCheckOutDate(moment(this.order.hotel_end_date).startOf('day')._d)
      this.setItemAndHotels()
      this.updateEventLocation(this.order.event_location)
      if (this.order.date_translate) {
        if(this.order.date_translate == 'TBD') {
          this.updateTBDStatus(true)
        }
      }
    }
  },

  created() {
    this.setLoading(true)
    window.history.replaceState(null, null, 'hotels')

    if (
      window.location.pathname.charAt(window.location.pathname.length - 1) ===
      '/'
    ) {
      window.history.replaceState(null, null, '../hotels')
    } else {
      window.history.replaceState(null, null, './hotels')
    }
  },

  updated() {
    this.redirectIfNoPkg()
  },

  methods: {
    ...mapMutations('hotel', [
      'setLoading',
      'updateDestination',
      'updateH4hDestination',
      'updateH4hPackage',
      'updateVenue',
      'updateLatitude',
      'updateH4hLatitude',
      'updateLongitude',
      'updateH4hLongitude',
      'updateCheckInDate',
      'updateCheckOutDate',
      'updateTBDStatus',
      'deselectHotel',
      'showRetailIndex',
      'addHotelToArray',
      'selectHotel',
      'setContractedHotels'
    ]),

    ...mapMutations('event', ['updateEventLocation']),

    ...mapMutations('packages', ['setPackageId', 'updatePackageQty', 'setPackageWithoutHotelId']),

    deselectSpecifiedHotel() {
      window.history.pushState(null, null, './hotels')

      this.deselectHotel()
    },
    async getHotels() {
      //UNCOMMENT BELOW FOR PHASE 2 H4H INTEGRATION WHICH INCLUDES CONTRACTED HOTELS
      if(this.$store.state.hotel.contractedHotels.length <= 0){
        let location = window.location.href.slice(0, -7)
        this.getHotelNeeded()
        this.eventId = location.substring(location.lastIndexOf('/') + 1)
        if(this.h4hAddOn){
          let res = await FetchCall.fetchWithoutLocale(
            `/api/events/${this.eventId}/post_purchase_contracted_hotels`,
            'GET',
            null
          )
          this.hotels = res.hotels
          await this.createHotelObject(true)
        } else {
          let res = await FetchCall.fetchWithoutLocale(
            `/api/packages/${this.$store.state.packages.packageWithoutHotelId}/hotels`,
            'GET',
            null
          )
          this.hotels = res.hotels
          await this.createHotelObject(true)
        }
        // this.setContractedHotels(res.hotels)

      }
    },

    getHotelNeeded(){
      let itemNeedingHotel = this.order.items.find(item => {
        return (item.hotel_needed && !item.room_quantity) ||
          (item.hotel_needed && item.room_quantity <= 0)
      })
      if(itemNeedingHotel){
        this.setPackageWithoutHotelId(itemNeedingHotel.package_id)
      }
    },

    getItemNumber() {
      let pathArray = window.location.href.split('/')
      let pathItemId = pathArray.reverse()[1]
      let itemId = Number(pathItemId)
      return itemId
    },
    redirectIfNoPkg(){
      // if(this.order.items.length===0){
      //   Turbo.visit('/packages')
      // }
    },

    async setItemAndHotels() {
      let item = null

      if(this.order.items){
        item = this.order.items[0]
      }

      if (item) {
        await this.setItem(item)
      } else {
        let eventDiv = document.getElementById('eventIdForHotels')
        this.eventId = eventDiv.dataset.id
        await FetchCall.fetchWithoutLocale(
          `/cart/events/${this.eventId}/hotels.json`,
          'POST',
          JSON.stringify({eventId: this.eventId})
        ).then( item => {
          this.setItem(item)
        })
      }
    },

    async setItem(item) {
      this.orderItem = item
      this.setPackageJustAdded(item.created_at)
      this.setPackageId(item.package_id)
      this.updatePackageQty(item.quantity)

      if(this.$store.state.hotel.hotels.length == 0){
        await this.getHotels()
      }
      if (item.hotel_id) {
        this.checkHotelInCart(item)
      }
    },

    goToPackagePath() {
      const packageId = this.$store.state.packages.packageId
      window.location.href = `/${locale}/packages/${packageId}/path`
    },

    goToCheckoutPage() {
      Turbo.visit('/cart/checkout')
    },

    setPackageJustAdded(created_at) {
      let date = new Date()
      let dateCreated = new Date(created_at)

      if (date.getTime() - 15000 < dateCreated.getTime()) {
        this.packageJustAdded = true
        setTimeout(() => {
          this.packageJustAdded = false
        }, 20000)
      }
    },

    checkHotelInCart(item) {
      let hotels = this.getContractedHotels
      let hotel = hotels.find((hotel) => {
        return hotel.qng_id == item.hotel_id
      })
      if (hotel) {
        this.selectHotel(hotel)
        window.history.pushState(hotel.id, null, `hotels#${hotel.id}`)
        window.addEventListener('popstate', (e) => {
          e.state !== null ? this.selectHotel(hotel) : this.selectHotel(null)
        })
      } else if (item.hotel_reservations[0] && item.hotel_reservations[0].hotel_id) {
        this.fetchH4HHotelInfo(item.hotel_reservations[0].attributes)
      }
    },

    async fetchH4HHotelInfo(hotelAttributes) {
      moment.locale(locale)
      let checkIn = moment(hotelAttributes.hotel_check_in_date)._d
      let checkOut = moment(hotelAttributes.hotel_check_out_date)._d

      let res = await FetchCall.fetchWithoutLocale(
        '/api/h4h_hotels' +
        `?latitude=${hotelAttributes.hotel_latitude}` +
        `&longitude=${hotelAttributes.hotel_longitude}` +
        `&inDate=${moment(checkIn).format('YYYY-MM-DD')}` +
        `&outDate=${moment(checkOut).format('YYYY-MM-DD')}` +
        `&rooms=${hotelAttributes.room_quantity}`, +
        `&arnSiteId=${this.order.event_arn_site_id}`,
        'GET',
        null
      )
      let hotels = res.hotels

      let index = hotels
        .map((x) => {
          return x['@HotelID']
        })
        .indexOf(hotelAttributes.hotel_id)
      let hotel = new Hotel(hotels[index])
      await hotel.updateHotelInfo()

       
      hotel.checkIn = checkIn
       
      hotel.checkOut = checkOut
       
      hotel.stayDuration = moment(checkOut).diff(checkIn, 'days')

      this.addHotelToArray(hotel)
      this.selectHotel(hotel)
      window.history.pushState(hotel.id, null, `hotels#${hotel.id}`)
      window.addEventListener('popstate', (e) => {
        e.state !== null ? this.selectHotel(hotel) : this.selectHotel(null)
      })
      this.setLoading(false)
    },

    updateOrderCurrency(event){
      this.order.currency = event.target.dataset.currency
      this.order.exchange_rate = event.target.dataset.rate
    },

    updateRoomQty(qty) {
      if(qty) { this.roomQty = qty }
    },
  },
}
</script>
